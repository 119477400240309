import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function GetHelpDialog({ open, onAccept, onCancel, helper }) {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [street, setStreet] = useState('');
  const [district, setDistrict] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({
    firstname: false,
    lastname: false,
    street: false,
    district: false,
    phone: false,
  });
  const [touched, setTouched] = useState({
    firstname: false,
    lastname: false,
    street: false,
    district: false,
    phone: false,
  })

  useEffect(() => {
    setErrors({
      firstname: !firstname && touched.firstname,
      lastname: !lastname && touched.lastname,
      street: !street && touched.street,
      district: !district && touched.district,
      phone: !phone && touched.phone,
    });
  }, [firstname, lastname, street, district, phone, touched])

  function handleOnAccept(e) {
    if (
      !firstname ||
      !lastname ||
      !district ||
      !street ||
      !phone ||
      Object.values(errors).indexOf(i => i  ) > -1
    ) {
      e.preventDefault();
      setErrors({
        firstname: !firstname,
        lastname: !lastname,
        street: !street,
        district: !district,
        phone: !phone,
      });
      return;
    }
    if (Object.values(errors).indexOf(i => i) > -1) {
      e.preventDefault();
      return;
    }
    onAccept(firstname, lastname, street, district, phone);
    reset();
  }

  function handleOnCancel() {
    reset();
    onCancel();
  }

  function reset() {
    setFirstname('');
    setLastname('');
    setStreet('');
    setDistrict('');
    setPhone('');
    setErrors({
      firstname: false,
      lastname: false,
      street: false,
      district: false,
      phone: false,
    });
    setTouched({
      firstname: false,
      lastname: false,
      street: false,
      district: false,
      phone: false,
    })
  }

  const subject = `Hilfsangebot von ${helper?.['Vorname']} ${helper?.['Zeitstempel'] ? `vom ${helper?.['Zeitstempel']}` : ''}`;
  const body = `
    Hallo liebes #emdenhilft-Team,%0D%0A
    %0D%0A
    ich würde gerne das Hilfsangebot von ${helper?.['Vorname']} ${helper?.['Zeitstempel'] ? `vom ${helper?.['Zeitstempel']}` : ''} wahrnehmen.%0D%0A
    %0D%0A
    Mit freundlichen Grüßen.%0D%0A
    %0D%0A
    Name: ${firstname} ${lastname}%0D%0A
    Stadtteil: ${district}%0D%0A
    Straße / Hausnummer: ${street}%0D%0A
    Telefon: ${phone}%0D%0A
  `;

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Hilfe anfordern
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Um Hilfe per Mail anzufordern trag' bitte noch Deine Kontaktdaten ein.
          Diese werden nur benutzt um eine E-Mail zu generieren, die Du dann noch selbständig
          an uns abschicken musst.
          Deine Daten werden sonst nicht weiterverarbeitet.
        </DialogContentText>
        <TextField
          margin="dense"
          id="firstname"
          label="Vorname"
          type="text"
          fullWidth
          onBlur={e => setTouched(prev => ({
            ...prev,
            firstname: true,
          }))}
          onChange={e => setFirstname(e.target.value)}
          error={errors['firstname']}
        />
        <TextField
          margin="dense"
          id="lastname"
          label="Nachname"
          type="text"
          fullWidth
          onChange={e => setLastname(e.target.value)}
          onBlur={e => setTouched(prev => ({
            ...prev,
            lastname: true,
          }))}
          error={errors['lastname']}
        />
        <TextField
          margin="dense"
          id="street"
          label="Straße / Hausnummer"
          type="text"
          fullWidth
          onChange={e => setStreet(e.target.value)}
          error={errors['street']}
          onBlur={e => setTouched(prev => ({
            ...prev,
            street: true,
          }))}
        />
        <TextField
          margin="dense"
          id="district"
          label="Stadtteil"
          type="text"
          fullWidth
          onChange={e => setDistrict(e.target.value)}
          error={errors['district']}
          onBlur={e => setTouched(prev => ({
            ...prev,
            district: true,
          }))}
        />
        <TextField
          margin="dense"
          id="phone"
          label="Telefonnummer"
          type="tel"
          fullWidth
          onChange={e => setPhone(e.target.value)}
          error={errors['phone']}
          onBlur={e => setTouched(prev => ({
            ...prev,
            phone: true,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnCancel} color="primary">
          Abbrechen
        </Button>
        <Button
          color="primary"
          size="small"
          component="a"
          href={`mailto:emden-hilft@emden.de?subject=${subject}&body=${body}`}
          onClick={handleOnAccept}
        >
          Fertig
        </Button>
      </DialogActions>
    </Dialog>
  );
}