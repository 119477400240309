import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { config, useSpring } from 'react-spring';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FAB from '@material-ui/core/Fab';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import chunk from 'lodash/chunk';
import moment from 'moment';
import useGoogleSpreadsheet from '../hooks/useGoogleSpreadSheet';
import GetHelpDialog from '../components/GetHelpDialog';
import LoadSpinner from '../components/LoadSpinner';
import Layout from '../components/Layout';
import HelperCounter from '../components/HelperCounter';
import HelperCard from '../components/HelperCard';
import SEO from '../components/SEO'

const SHEET_ID = `1k_mfG-yutYQcNJ0P_oZ7WHnlwmG2i-LdBnZhyKvfL7g`;
const API_KEY = `AIzaSyDhcyA0uxfUJ1OLkv_XamRtoTlXRpryf0M`;

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
  },
  hr: {
    margin: theme.spacing(4, 0),
  },
  fab: {
    position: `fixed`,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  icon: {
    transform: `scaleX(-1)`,
    color: `white`,
  },
  progressWrapper: {
    display: `flex`,
    justifyContent: `center`,
  },
}));

const usePaginatorStyles = makeStyles(theme => ({
  wrapper: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    margin: theme.spacing(2, 0),
  },
}));

function Paginator({ currentPage, pageCount, onPrevButtonClick, onNextButtonClick }) {
  const classes = usePaginatorStyles();
  return (
    <div className={classes.wrapper}>
      <Button onClick={onPrevButtonClick}>Zurück</Button>
      <Typography variant="body2">{`${currentPage} / ${pageCount}`}</Typography>
      <Button onClick={onNextButtonClick}>Nächste</Button>
    </div>
  );
}

function useScrollToTop() {
  const [, setY] = useSpring(() => ({ y: 0 }))
  
  const scrollToTop = useCallback(() => {
    setY({
      y: 0,
      reset: true,
      from: { y: window.scrollY },
      onFrame: props => window.scroll(0, props.y),
      config: {
        ...config.stiff,
        clamp: true,
      }
    });
  }, [setY]);

  return scrollToTop;
}

function HilfeFinden() {
  const classes = useStyles();
  const [helper, setHelper] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rows, loading] = useGoogleSpreadsheet(SHEET_ID, API_KEY);
  const scrollToTop = useScrollToTop();
  const chunks = useMemo(() => {
    const rowsWithMoments = rows.map(row => ({
      ...row,
      moment: moment(row['Zeitstempel'], "DD.MM.YYYY hh:mm:ss")
    }));
    const sorted = [...rowsWithMoments].sort((a, b) => b.moment.diff(a.moment));
    return chunk(sorted, 20);
  }, [rows]);

  const prev = () => setActivePage(prev => prev - 1 > - 1 ? prev - 1 : chunks.length - 1);
  const next = () => setActivePage(prev => prev + 1 < chunks.length - 1 ? prev + 1 : 0);
  const onAccept = () => {
    setDialogOpen(false);
  }

  const onCancel = () => {
    setDialogOpen(false);
  }

  const onRequestHelp = (helper) => {
    setHelper(helper);
    setDialogOpen(true);
  }

  const paginator = (
    <Paginator
      currentPage={activePage + 1}
      pageCount={chunks.length}
      onPrevButtonClick={prev}
      onNextButtonClick={next}
    />
  );

  useEffect(() => {
    scrollToTop();
  }, [activePage, scrollToTop]);

  return (
    <>
      <Layout>
        <SEO
          title="Hilfe finden"
          description={`Du lebst in Emden und brauchst Hilfe in Zeiten der Corona Krise? Auf "Emden hilft" findest Du Helfer in deiner Stadt.`}
        />
        <Container className={classes.container} maxWidth="sm">
          <section>
            <HelperCounter count={rows.length} />
          </section>
          <hr className={classes.hr} />

          {loading && (
            <div className={classes.progressWrapper}>
              <LoadSpinner />
            </div>
          )}
          {chunks[activePage] && (
            <>     
              {paginator}
              {chunks[activePage]?.map((helper, i) => (
                <HelperCard
                  key={`${activePage}_${i}`}
                  helper={helper}
                  onRequestHelp={onRequestHelp}
                />
              ))}
              {paginator}
            </>
          )}
        </Container>
        <FAB
          className={classes.fab}
          color="secondary"
          href="tel:+494921871120"
          component="a"
        >
          <PhoneIcon className={classes.icon} />
        </FAB>
        <GetHelpDialog
          helper={helper}
          open={dialogOpen}
          onAccept={onAccept}
          onCancel={onCancel}
        />
      </Layout>
    </>
  )
}

export default HilfeFinden;
