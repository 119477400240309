import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  image: {
    marginBottom: theme.spacing(2),
    width: 56,
    animation: `$rotate 3s infinite linear`,
  },
  "@keyframes rotate": {
    '0%': {
      transform: "rotate(0deg)",
    },
    '50%': {
      transform: "rotate(180deg)",
    },
    '100%': {
      transform: "rotate(360deg)",
    },
  },
  pulsate: {
    animation: `$pulsate 1s infinite linear`,
  },
  "@keyframes pulsate": {
    '0%': {
      transform: "scale(0.9)",
    },
    '50%': {
      transform: "scale(1)",
    },
    '100%': {
      transform: "scale(0.9)",
    },
  }
}));
const imageQuery = graphql`
  query {
    findHelp: file(relativePath: { eq: "rettungsring.png" }) {
      childImageSharp {
        fluid(maxWidth: 56, quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

function LoadSpinner() {
  const classes = useStyles();
  const imageData = useStaticQuery(imageQuery)
  return (
    <div className={classes.pulsate}>
      <Img
      className={classes.image}
      fluid={imageData.findHelp.childImageSharp.fluid} 
      />
    </div>
  )
}

export default LoadSpinner
