import React from 'react'

const v4URL = (sheetID, apiKey) =>
  `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}?includeGridData=true&key=${apiKey}`;

const getDataValues = row => row.values.map(value => value.formattedValue);

const convertSheetsToSimpleJson = (rows = []) => {
  const keys = getDataValues(rows[0]);
  return rows
    .filter((_, i) => i > 0)
    .map(row => {
      const values = getDataValues(row);
      return keys.reduce((simpleRow, key, i) => {
        return { _id: i + 1, ...simpleRow, [key]: values[i] || null };
      }, {});
    });
};

function useGoogleSpreadSheet(sheetID, apiKey) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    fetch(v4URL(sheetID, apiKey))
      .then(res => res.json())
      .then(data => convertSheetsToSimpleJson(data.sheets[0].data[0].rowData))
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [sheetID, apiKey]);

  return [data, loading, error];
}

export default useGoogleSpreadSheet
