import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { animated, config, useSpring } from 'react-spring';

const useStyles = makeStyles(theme => ({
  mono: {
    display: `block`,
    fontFamily: `Monospace`,
    fontSize: `4rem`
  },
  helpers: {
    display: `block`,
    marginBottom: theme.spacing(1),
  },
}));

function HelperCounter({ count }) {
  const classes = useStyles();
  const props = useSpring({
    from: { number: 0 },
    number: count,
    config: { ...config.stiff, clamp: true }
  })
  return (
    <>
      <Typography variant="body2" align="center">
        Bisher sind
      </Typography>
      <Typography
        className={classes.mono}
        variant="h2"
        component={animated.span}
        align="center"
        style={props}
      >
        {props.number.interpolate(n => Math.floor(n))}
      </Typography>
      <Typography className={classes.helpers} variant="h4" component="span" align="center">
        Helfer/innen
      </Typography>
      <Typography variant="body2" align="center">
        mit  <span role="img" aria-label="Herz">❤️</span> dabei
      </Typography>
    </>
  );
}

export default HelperCounter
