import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import PlaceIcon from '@material-ui/icons/Place';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import 'moment/locale/de'

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  body2: {
    marginTop: theme.spacing(2),
    fontWeight: `bold`,
  },
  placeIcon: {
    minWidth: 32,
    color: `#20639B`
  },
  listItemIcon: {
    minWidth: 32,
    color: `#20639B`
  },
  listItem: {
    margin: 0,
    padding: 0,
  },
  check: {
    color: theme.palette.success.main
  },
  clear: {
    color: theme.palette.error.main,
  }
}))

function HelperCard({ helper, onRequestHelp }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image="/static/images/cards/contemplative-reptile.jpg"
        title="Contemplative Reptile"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {helper['Vorname']}
        </Typography>
        {helper['Zeitstempel'] && (
          <Typography gutterBottom variant="caption">
            hat sich vor {helper.moment?.locale('de').fromNow()} eingetragen
          </Typography>
        )}
          <>
            {(helper['Eigener PKW vorhanden'] || helper['Stadtteil']) &&  (
              <Typography variant="body2" className={classes.body2}>
                Allgemein
              </Typography>
            )}
            <List dense>
              {helper['Stadtteil'] && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.placeIcon}>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={helper['Stadtteil']}
                  />
                </ListItem>
              )}
              {helper['Eigener PKW vorhanden']  && (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.placeIcon}>
                    {helper['Eigener PKW vorhanden'] === 'Ja' ? (
                      <CheckIcon className={classes.check} />
                    ) : (
                      <ClearIcon className={classes.clear} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={"Eigener PKW vorhanden"} />
                </ListItem>
              )}
              </List>
            </>
        {helper['Hilfsangebot'] && (
          <>
            <Typography variant="body2" className={classes.body2}>
              Mein Hilfsangebot
            </Typography>
            <List dense>
              {helper['Hilfsangebot'].split(', ').map(help => (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={help}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          className={classes.button}
          onClick={() => onRequestHelp(helper)}
        >
          Per E-Mail anfordern
        </Button>
      </CardActions>
    </Card>
  )
}

export default HelperCard
